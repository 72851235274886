<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Ticket Holder
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="holder-form">
          <v-switch
            label="Principal"
            v-model="fields.is_principal"
            inset
            color="success"
            class="mt-0"
          ></v-switch>
          <v-text-field
            label="Full Name *"
            v-model="fields.full_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('full_name')"
            :error-messages="errors['full_name']"
            :disabled="fields.is_guest || fields.is_principal"
          ></v-text-field>
          <v-file-input
            label="Ticket Holder's Photo"
            accept="image/png, image/jpeg"
            placeholder="Pick a photo"
            prepend-icon="mdi-camera"
            outlined
            background-color="white"
            :disabled="fields.is_guest"
          ></v-file-input>
          <v-switch
            label="Is a Guest?"
            v-model="fields.is_guest"
            inset
            color="success"
            class="mt-0"
            :disabled="fields.is_principal"
            :error="errors.hasOwnProperty('is_guest')"
            :error-messages="errors['is_guest']"
          ></v-switch>
          <v-switch
            label="Is an Additional Ticket?"
            v-model="fields.is_additional"
            inset
            color="success"
            class="mt-0"
            :disabled="fields.is_principal"
            :error="errors.hasOwnProperty('is_additional')"
            :error-messages="errors['is_additional']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="holder-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      holder: {},
      fields: {
        full_name: null,
        is_guest: false,
        is_additional: false,
        is_principal: false,
      },
      errors: {},
    };
  },

  methods: {
    open(holder = null) {
      if (holder !== null) {
        this.holder = holder;
        this.fields.full_name = holder.full_name;
        this.fields.is_guest = holder.is_guest;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.holder = {};
      this.fields.full_name = null;
      this.fields.is_guest = false;
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
        ticketId: this.$route.params.ticketId,
      };

      if (this.isEditing) {
        payload.holderId = this.holder.id;
      }

      this.$store
        .dispatch("craigtoun/ticketsStore/saveHolder", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
